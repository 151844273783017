<script setup lang="ts">
import { ref, watch } from 'vue'
import { storeToRefs } from 'pinia'
import { array, date, number, object } from 'yup'
import { useForm } from 'vee-validate'
import { toTypedSchema } from '@vee-validate/yup'
import { t } from '@/common/i18n'
import SvgIcon from '@/common/icons/SvgIcon.vue'
import FooterDialog from '@/components/partials/FooterDialog.vue'
import TimeRangePicker from '@/components/rates/TimeRangePicker.vue'
import BaseDialog from '@/components/ui/BaseDialog.vue'
import BaseInputNumber from '@/components/ui/BaseInputNumber.vue'
import type { IndexedSchedule, Schedule } from '@/models/domain/rate/api/Rate.ts'
import { useApi } from '@/stores/api/api.ts'
import { capitalizeString } from '@/utils/capitalize.ts'

const { updatedSchedule, updating } = defineProps<{
  updatedSchedule?: IndexedSchedule
  updating?: boolean
}>()

const visible = defineModel<boolean>('visible')

const emit = defineEmits<{
  (e: 'pushSchedule', newSchedule: Schedule): void
  (e: 'updateSchedule', newSchedule: Schedule, id: number): void
}>()

const { loading } = storeToRefs(useApi())

const schema = toTypedSchema(
  object({
    days: array()
      .of(number().required(() => t('validation.required')))
      .min(1, () => t('validation.required'))
      .max(7)
      .default([]),
    timeRange: object({
      start: date().required(() => t('validation.required')),
      end: date().required(() => t('validation.required'))
    }).default({ start: null, end: null }),
    cost: number().required(() => t('validation.required'))
  })
)

const { defineField, handleSubmit, resetForm, errors, meta } = useForm({
  validationSchema: schema
})

const [days] = defineField('days')
const [timeRange] = defineField('timeRange')
const [cost] = defineField('cost')

const weekdays = ref([
  { label: t('locale.days.monday'), code: 1 },
  { label: t('locale.days.tuesday'), code: 2 },
  { label: t('locale.days.wednesday'), code: 3 },
  { label: t('locale.days.thursday'), code: 4 },
  { label: t('locale.days.friday'), code: 5 },
  { label: t('locale.days.saturday'), code: 6 },
  { label: t('locale.days.sunday'), code: 0 }
])

const updateTimeRange = (newTimeRange) => {
  if (timeRange.value) {
    timeRange.value = newTimeRange
  }
}

const onSubmit = handleSubmit(async (values) => {
  if (updatedSchedule) {
    emit('updateSchedule', values, updatedSchedule.id)
  } else {
    emit('pushSchedule', values)
  }
  visible.value = false
})

const handleCancel = () => {
  visible.value = false
}

watch(visible, () => {
  resetForm({
    values: {
      ...updatedSchedule
    }
  })
})
</script>

<template>
  <BaseDialog
    v-model:visible="visible"
    :closable="false"
    :style="{ width: '50vw', minWidth: '44rem', maxWidth: '55rem' }"
  >
    <template #title>
      <div class="absolute top-0 left-0 mt-4 mb-5 ml-4">
        <p class="p-dialog-title">
          {{
            updating
              ? t('detail.rate.dialog.editRangeHours')
              : t('detail.rate.dialog.addRangeHours')
          }}
        </p>
      </div>
    </template>
    <template #header>
      <div class="absolute top-0 right-0 mt-4 mr-3">
        <svg-icon name="clock" size="24" color="white" />
      </div>
    </template>
    <template #body>
      <div class="flex flex-column justify-content-between">
        <div class="flex flex-row justify-content-between">
          <div class="field col-6">
            <label class="font-family-light required" for="days">{{
              t('detail.rate.header.days')
            }}</label>
            <MultiSelect
              v-model="days"
              id="days"
              class="multi-select align-items-center"
              aria-describedby="days-help"
              display="chip"
              optionLabel="label"
              optionValue="code"
              :loading
              :maxSelectedLabels="4"
              :options="weekdays"
              :placeholder="t('detail.rate.dialog.placeholder.days')"
            >
              <template #dropdownicon>
                <svg-icon name="calendar" size="18" color="#9E9E9E" />
              </template>
              <template #option="slotProps">
                <div class="flex align-items-center">
                  <div class="text-color">{{ capitalizeString(slotProps.option.label) }}</div>
                </div>
              </template>
            </MultiSelect>
            <small id="days-help" class="p-error">
              {{ errors.days }}
            </small>
          </div>
          <div class="field col-6">
            <label class="font-family-light required" for="cost">{{
              t('detail.rate.dialog.cost')
            }}</label>
            <BaseInputNumber
              v-model="cost"
              id="cost"
              aria-describedby="cost-help"
              suffix=" €"
              :min="0"
              :placeholder="t('detail.rate.dialog.cost')"
              :step="0.01"
            />
            <small id="cost-help" class="p-error">
              {{ errors.cost }}
            </small>
          </div>
        </div>
      </div>
      <TimeRangePicker :updatedSchedule="updatedSchedule" @update-time-range="updateTimeRange" />
    </template>
    <template #footer>
      <div class="flex xl:flex-row sm:flex-column justify-content-end mt-4">
        <FooterDialog @cancel="handleCancel" @confirm="onSubmit" remove :disabled="!meta.valid" />
      </div>
    </template>
  </BaseDialog>
</template>

<style scoped lang="scss"></style>
