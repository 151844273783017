import { defineStore } from 'pinia'
import { socket } from '@/config/socket'
import { TopicNameSpace } from '@/models/common/TopicNameSpace'
import type { WebSocketStatus } from '@/models/ocpp/enums'
import type { ChargerStatus } from '@/models/ocpp/Broadcast'

export const useChargerConnectionStatusStore = defineStore('charger-connection-status', {
  state: () => ({
    chargersConnectionStatus: {} as { [key: string]: WebSocketStatus }
  }),
  getters: {
    getChargersConnectionStatus: (state) => state.chargersConnectionStatus,
    getChargerConnectionStatusByCpId: (state) => (cpId: string) => {
      return state.chargersConnectionStatus[cpId]
    }
  },

  actions: {
    bindEvents() {
      socket.on(TopicNameSpace.chargerStatus, (data: ChargerStatus) => {
        const { cpId, status } = data
        this.setChargerConnectionStatus(cpId, status)
      })
    },

    setChargerConnectionStatus(cpId: string, status: WebSocketStatus) {
      this.chargersConnectionStatus[cpId] = status
    }
  }
})
