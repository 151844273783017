<script setup lang="ts">
import { onMounted, ref, watch } from 'vue'
import { i18n, t } from '@/common/i18n'
import { storeToRefs } from 'pinia'
import { useRoute } from 'vue-router'
import { useToast } from 'primevue/usetoast'
import type { UTCTimestamp } from 'lightweight-charts'
import { RouteNamespace } from '@/models/common/RouteNameSpace'
import { ApiService } from '@/services/ApiService'
import { useApi } from '@/stores/api/api'
import type { Connector, ConnectorType, Rate } from '@/models'
import clock from '@/assets/lottie/clock.json'
import BaseLottieAnimation from '@/components/ui/BaseLottieAnimation.vue'
import BaseChart from '@/components/ui/BaseChart.vue'
import { capitalizeString } from '@/utils/capitalize'
import BaseConfirmDeletePopup from '@/components/ui/BaseConfirmDeletePopup.vue'
import BasePushNotification from '@/components/ui/BasePushNotification.vue'
import {
  AvailabilityType,
  ChargePointStatus,
  Measurand,
  UnitOfMeasure,
  UnlockStatus,
  WebSocketStatus as ChargerWsStatus
} from '@/models/ocpp/enums'
import type { MeterValue, MeterValuesWithCpId } from '@/models/ocpp/MeterValuesRequest'
import { getClassForStatusStore, getStatusTranslationStore } from '@/utils/status'
import BaseInputSwitchRows from '@/components/ui/BaseInputSwitchRows.vue'
import { StationStatusMap } from '@/models/domain/location/enums'
import { findMeterValueByMeasurand } from '@/utils/getMostRecentMeterValue'
import type { TypeGraphData } from '@/models/ui/Connector'
import BaseDialog from '@/components/ui/BaseDialog.vue'
import SvgIcon from '@/common/icons/SvgIcon.vue'
import AppTopbar from '@/layout/AppTopbar.vue'
import type { Rfid } from '@/models/domain/station/api/Station'
import {
  useChargePointStatusStore,
  useChargerConnectionStatusStore,
  useMeterValues,
  useResponsesOcpp
} from '@/stores/ocpp'
import BaseBadge from '@/components/ui/BaseBadge.vue'
import { TopicNameSpace } from '@/models/common/TopicNameSpace'
import { type ActionRequested, StatusRequest } from '@/models/ocpp/Broadcast'

//TODO reset chart data when the transaction is changed
//TODO tendría que tener acceso a la transactionId actual, si ya esta acabada aunque este dentro del rango de tiempo, no dibujar???
const { loading } = storeToRefs(useApi())
const DATA_LIMIT = 15
const CHADEMO = 'CHAdeMO'
const initialData = { time: (Date.now() / 1000) as UTCTimestamp, value: 0 }
const toast = useToast()
const route = useRoute()
const connectors = ref<Connector[]>([])
const connectorTypes = ref<ConnectorType[]>([])
const rates = ref<Rate[]>([])
const expandedRows = ref([])
const totalRecords = ref<number>(1)
const connectorSelected = ref()
const enabled = ref<boolean>(true)
const visible = ref<boolean>(false)
const alias = ref<string>('')
const popup = ref()
const chartType = ref('baseline')
const dataCurrent = ref<Array<Array<TypeGraphData>>>([])
const dataPower = ref<Array<Array<TypeGraphData>>>([])
const cpId = ref<string>('')
const toastSummary = ref('')
const selectIdTagVisible = ref(false)
const selectExpiryDateVisible = ref(false)
const selectedIdTag = ref<string>()
const selectExpiryDate = ref<Date | undefined>()
const rfIds = ref<Rfid[]>([])
const selectedNumberConnector = ref(0)
//ocpp switches
const changeAvailabilitySwitch = ref<Array<number>>([])
const changeAvailabilityDisabled = ref<Array<boolean>>([])

//ocpp disabled buttons
const disableAll = ref(false)
const startChargerDisabled = ref<Array<boolean>>([])
const stopChargerDisabled = ref<Array<boolean>>([])
const unlockConnectorDisabled = ref<Array<boolean>>([])
const reserveNowDisabled = ref<Array<boolean>>([])
const cancelReservationDisabled = ref<Array<boolean>>([])

//Charts
const timeScaleOptions = {
  timeVisible: true
}
const chartOptions = ref({
  height: 220,
  layout: {
    textColor: 'darkgray',
    fontFamily: 'Lato'
  },
  grid: {
    vertLines: {
      color: 'rgba(197, 203, 206, 0.5)'
    },
    horzLines: {
      color: 'rgba(197, 203, 206, 0.5)'
    }
  },
  rightPriceScale: {
    borderColor: 'rgba(197, 203, 206, 0.8)'
  },
  timeScale: {
    borderColor: 'rgba(197, 203, 206, 0.8)'
  }
})

//use ocpp stores
const meterValuesStore = useMeterValues()
const chargerConnectionStatusStore = useChargerConnectionStatusStore()
const chargePointStatusStore = useChargePointStatusStore()
const responsesOcppStore = useResponsesOcpp()

const priceScaleOptionPower = {
  localization: {
    priceFormatter: (p: number) => {
      const formattedP = p.toFixed(2)
      return formattedP + UnitOfMeasure.KW
    }
  }
}
const priceScaleOptionCurrent = {
  localization: {
    priceFormatter: (p: number) => {
      const formattedP = p.toFixed(2)
      return formattedP + UnitOfMeasure.A
    }
  }
}
const seriesOptions = ref({
  topLineColor: '#27A69A',
  topFillColor1: '#A4DAD4',
  topFillColor2: '#ECF7F6'
})

const checkValidTimestamp = (
  newTimestamp: number,
  indexConnector: number,
  arrayData: TypeGraphData[][]
) => {
  if (!arrayData[indexConnector]) return true
  const dataArray = arrayData[indexConnector]
  //UTC+2 return negative value in minutes, uses browser local time as reference
  const offset = new Date(newTimestamp).getTimezoneOffset() * 60 * 1000
  const newTimestampInSeconds = (newTimestamp - offset) / 1000
  if (dataArray.length > 0) {
    const lastIndex = dataArray.length - 1
    const lastTimestamp = dataArray[lastIndex].time

    if (lastTimestamp >= newTimestampInSeconds) {
      console.error('Discarding historical meterValue data')
      return false
    }
  }
  return true
}

const buildObjectPointToDraw = (meterValue: MeterValue, measurand: Measurand) => {
  const timestamp = Date.parse(meterValue.timestamp)
  const filterSampleByCurrent = meterValue.sampledValue.filter(
    (sample) => sample.measurand !== undefined && sample.measurand === measurand
  )
  if (filterSampleByCurrent.length > 0) {
    let value = Number(filterSampleByCurrent[0].value)
    if (isNaN(value)) return
    let factor = 1
    //current import unit is A not need to convert it
    if (measurand !== Measurand.CURRENT_IMPORT) {
      if (filterSampleByCurrent[0].unit) {
        //if unit data is in W, change it to kW
        if (filterSampleByCurrent[0].unit.toLowerCase().indexOf('k') === -1) {
          factor = 0.001
        }
      }
    }
    value = value * factor
    //UTC+2 return negative value in minutes, uses browser local time as reference
    const offset = new Date(timestamp).getTimezoneOffset() * 60 * 1000
    const time = (timestamp - offset) / 1000
    return { time, value }
  }
  return
}

const saveNewPoint = (point: TypeGraphData, connectorIndex: number, measurand: Measurand) => {
  if (!dataCurrent.value) dataCurrent.value = []
  if (!dataPower.value) dataPower.value = []
  if (measurand === Measurand.CURRENT_IMPORT) {
    if (!dataCurrent.value[connectorIndex]) dataCurrent.value[connectorIndex] = []
    if (dataCurrent.value[connectorIndex].length >= DATA_LIMIT)
      dataCurrent.value[connectorIndex].shift()
    dataCurrent.value[connectorIndex].push(point)
  } else if (measurand === Measurand.POWER_ACTIVE_IMPORT) {
    if (!dataPower.value[connectorIndex]) dataPower.value[connectorIndex] = []
    if (dataPower.value[connectorIndex].length >= DATA_LIMIT)
      dataPower.value[connectorIndex].shift()
    dataPower.value[connectorIndex].push(point)
  }
}
const getPointToDraw = (newValue: MeterValuesWithCpId) => {
  if (!newValue) return
  if ('connectorId' in newValue && 'transactionId' in newValue && 'meterValue' in newValue) {
    if (newValue.connectorId <= 0) {
      console.warn('Invalid connectorId:', newValue.connectorId)
      return
    }
    const connectorIndex = newValue.connectorId - 1

    if (connectorIndex < 0) {
      console.error('connectorIndex is invalid:', connectorIndex)
      return
    }
    //Variables to show in charts
    const chartMeasurands = [Measurand.CURRENT_IMPORT, Measurand.POWER_ACTIVE_IMPORT]
    chartMeasurands.forEach((measurand) => {
      const newMeterValue = findMeterValueByMeasurand(newValue.meterValue, measurand)
      if (!newMeterValue || !newMeterValue.timestamp) return
      const parsedTimestamp = Date.parse(newMeterValue.timestamp)
      if (
        isNaN(parsedTimestamp) ||
        !checkValidTimestamp(
          parsedTimestamp,
          connectorIndex,
          measurand === Measurand.CURRENT_IMPORT ? dataCurrent.value : dataPower.value
        )
      )
        return
      const values = buildObjectPointToDraw(newMeterValue, measurand)
      if (values) saveNewPoint(values, connectorIndex, measurand)
    })
  } else {
    console.error('newValue does not have the required properties')
  }
}

const initializeArrayData = (length: number, measurand: Measurand): Array<Array<TypeGraphData>> => {
  return Array.from({ length }, (el, index) => {
    const lastMeterValue = meterValuesStore.getMeterValuesByKey(`${cpId.value}:${index + 1}`)
    if (lastMeterValue) {
      //TODO distinguir si seria de la misma transacción??
      const meterValueByMeasurand = findMeterValueByMeasurand(lastMeterValue.meterValue, measurand)
      if (meterValueByMeasurand) {
        const point = buildObjectPointToDraw(meterValueByMeasurand, measurand)
        if (point) return [point]
      }
    }
    return [initialData]
  })
}

const initializeRefVariables = () => {
  //Initialize for each connector
  dataCurrent.value = initializeArrayData(totalRecords.value, Measurand.CURRENT_IMPORT)
  dataPower.value = initializeArrayData(totalRecords.value, Measurand.POWER_ACTIVE_IMPORT)
}

const prepareRfidCards = () => {
  return connectors.value[0]?.station.cards.forEach((rfid: Rfid) => {
    rfIds.value.push(rfid)
  })
}

const setChargerUI = () => {
  const chargerWsStatus = chargerConnectionStatusStore.getChargerConnectionStatusByCpId(cpId.value)
  const numberOfConnectors = connectors.value.length
  disableAll.value = chargerWsStatus === ChargerWsStatus.DISCONNECTED

  changeAvailabilitySwitch.value = Array.from({ length: numberOfConnectors + 1 }, (v, i) => {
    const connectorStatus = chargePointStatusStore.getChargePointStatusByConnector(cpId.value, i)
    if (!connectorStatus) return 0
    let codeStateSwitch = connectorStatus.status === ChargePointStatus.UNAVAILABLE ? 0 : 1
    if (i !== 0) {
      const key = `${cpId.value}:${i}:${TopicNameSpace.changeAvailability}`
      const pendingUnavailability = responsesOcppStore.getActionRequestedByKey(key)
      if (pendingUnavailability) {
        if (
          pendingUnavailability.statusRequest === StatusRequest.PENDING &&
          pendingUnavailability.target === AvailabilityType.INOPERATIVE
        ) {
          codeStateSwitch = 2
        }
      }
    }
    return codeStateSwitch
  })

  changeAvailabilityDisabled.value = Array.from({ length: numberOfConnectors + 1 }, (v, i) => {
    const connectorStatus = chargePointStatusStore.getChargePointStatusByConnector(cpId.value, i)
    return !connectorStatus || connectorStatus.status === ChargePointStatus.FAULTED
  })

  startChargerDisabled.value = Array.from({ length: numberOfConnectors }, (v, i) => {
    const connectorStatus = chargePointStatusStore.getChargePointStatusByConnector(
      cpId.value,
      i + 1
    )
    if (!connectorStatus) return true
    return ![ChargePointStatus.AVAILABLE, ChargePointStatus.PREPARING].includes(
      connectorStatus.status
    )
  })

  reserveNowDisabled.value = Array.from({ length: numberOfConnectors }, (v, i) => {
    const connectorStatus = chargePointStatusStore.getChargePointStatusByConnector(
      cpId.value,
      i + 1
    )
    if (!connectorStatus) return true
    return connectorStatus.status !== ChargePointStatus.AVAILABLE
  })

  cancelReservationDisabled.value = Array.from({ length: numberOfConnectors }, (v, i) => {
    const connectorStatus = chargePointStatusStore.getChargePointStatusByConnector(
      cpId.value,
      i + 1
    )
    if (!connectorStatus) return true
    return connectorStatus.status !== ChargePointStatus.RESERVED
  })

  stopChargerDisabled.value = Array.from({ length: numberOfConnectors }, (v, i) => {
    const connectorStatus = chargePointStatusStore.getChargePointStatusByConnector(
      cpId.value,
      i + 1
    )
    if (!connectorStatus) return true
    return ![
      ChargePointStatus.CHARGING,
      ChargePointStatus.SUSPENDED_EVSE,
      ChargePointStatus.SUSPENDED_EV
    ].includes(connectorStatus.status)
  })

  unlockConnectorDisabled.value = Array.from({ length: numberOfConnectors }, (v, i) => {
    const connectorStatus = chargePointStatusStore.getChargePointStatusByConnector(
      cpId.value,
      i + 1
    )
    if (!connectorStatus) return true
    return [ChargePointStatus.UNAVAILABLE, ChargePointStatus.AVAILABLE].includes(
      connectorStatus.status
    )
  })
}
const showExpandedData = (row: object) => {
  connectorSelected.value = row
}

const handleToast = (group, severity, summary) => {
  toastSummary.value = summary
  toast.add({
    life: 3000,
    group,
    severity
  })
}

const handleOCPPRequest = async (connector: Connector | number, route: string) => {
  try {
    let payload = {}
    let connectorId = 0
    if (typeof connector === 'number') connectorId = connector
    else {
      if ('connection' in connector) {
        connectorId = connector.connection
      }
    }
    //TODO disable all buttons if station is not enabled
    switch (route) {
      case RouteNamespace.remoteStartTransaction: {
        startChargerDisabled.value[connectorId - 1] = true
        connectorId = selectedNumberConnector.value
        selectIdTagVisible.value = false
        const statusStation = chargePointStatusStore.getChargePointStatusByConnector(
          cpId.value,
          connectorId
        )
        const chargerWsStatus = chargerConnectionStatusStore.getChargerConnectionStatusByCpId(
          cpId.value
        )
        if (chargerWsStatus === ChargerWsStatus.DISCONNECTED || !statusStation) {
          handleToast(
            RouteNamespace.remoteStartTransaction,
            'error',
            'Connector not available to start transaction'
          )
          break
        }
        const status = statusStation.status
        if (![ChargePointStatus.AVAILABLE, ChargePointStatus.PREPARING].includes(status)) {
          handleToast(
            RouteNamespace.remoteStartTransaction,
            'error',
            'Connector not available to start transaction'
          )
          break
        }

        const result = await handleStatusRequest(route, {
          cpId: cpId.value,
          connectorId,
          cardId: selectedIdTag.value
        })
        selectedIdTag.value = ''
        if (result['statusCode'] !== 200) {
          handleToast(RouteNamespace.remoteStartTransaction, 'error', 'Error starting transaction')
          startChargerDisabled.value[connectorId - 1] = false
          break
        }

        handleToast(
          RouteNamespace.remoteStartTransaction,
          'success',
          t('detail.connector.notification.requestSend')
        )
        break
      }

      case RouteNamespace.remoteStopTransaction: {
        payload = {
          cpId: cpId.value,
          connectorId: connectorId
        }
        await handleStatusRequest(route, payload as never)
        toast.add({
          life: 3000,
          group: RouteNamespace.remoteStopTransaction,
          severity: 'error'
        })
        toastSummary.value = t('detail.connector.notification.requestSend')
        break
      }

      case RouteNamespace.changeAvailability: {
        let type =
          chargePointStatusStore.getChargePointStatusByConnector(cpId.value, connectorId)
            ?.status === ChargePointStatus.UNAVAILABLE
            ? AvailabilityType.OPERATIVE
            : AvailabilityType.INOPERATIVE
        if (changeAvailabilitySwitch.value[connectorId] === 2) {
          type = AvailabilityType.OPERATIVE
          changeAvailabilityDisabled.value[connectorId] = false
        }
        payload = {
          cpId: cpId.value,
          connectorId: connectorId,
          type
        }
        await handleStatusRequest(route, payload as never)
        break
      }

      case RouteNamespace.unlockConnector: {
        unlockConnectorDisabled.value[connectorId] = true
        payload = {
          cpId: cpId.value,
          connectorId: connectorId
        }
        await handleStatusRequest(route, payload as never)
        toast.add({
          life: 3000,
          group: RouteNamespace.unlockConnector,
          severity: 'warn',
          detail: i18n.global.t('notifications.unlockConnector')
        })
        break
      }

      case RouteNamespace.reserveNow: {
        if (!selectExpiryDate.value) return
        reserveNowDisabled.value[connectorId - 1] = true
        selectExpiryDateVisible.value = false
        payload = {
          cpId: cpId.value,
          connectorId: connectorId,
          expiryDate: new Date(
            selectExpiryDate.value.getTime() - selectExpiryDate.value.getTimezoneOffset() * 60000
          ).toISOString(),
          cardId: selectedIdTag.value
        }
        selectedIdTag.value = ''
        selectExpiryDate.value = undefined

        await handleStatusRequest(route, payload as never)
        toast.add({
          life: 3000,
          group: RouteNamespace.reserveNow,
          severity: 'warn',
          detail: i18n.global.t('notifications.reserveNow')
        })
        break
      }
      case RouteNamespace.cancelReservation: {
        cancelReservationDisabled.value[connectorId - 1] = true
        selectExpiryDateVisible.value = false
        payload = {
          cpId: cpId.value,
          connectorId: connectorId
        }
        selectedIdTag.value = ''
        selectExpiryDate.value = undefined

        await handleStatusRequest(route, payload as never)
        toast.add({
          life: 3000,
          group: RouteNamespace.cancelReservation,
          severity: 'warn',
          detail: i18n.global.t('notifications.cancelReservation')
        })
        break
      }
      default: {
        console.warn('Unknown route:', route)
        break
      }
    }
  } catch (e) {
    console.error(e)
  } finally {
    loading.value = false
  }
}

const handleStatusRequest = async (route: string, payload: unknown) => {
  try {
    loading.value = true
    visible.value = false
    const result = await ApiService.createEntity(`${RouteNamespace.ocpp}/${route}`, payload)
    loading.value = false
    if (!result || !result['statusCode']) {
      return {
        statusCode: 500
      }
    }
    return result
  } catch (e) {
    return {
      statusCode: 500
    }
  }
}

const getConnectors = async () => {
  try {
    const { station, name } = route.params
    alias.value = name.toString()
    connectors.value = await ApiService.readEntity(
      `${RouteNamespace.connectors}/${station.toString()}`
    )
    //TODO at least one connector
    if (connectors.value[0].station?.cpId) {
      cpId.value = connectors.value[0].station?.cpId
    }
    totalRecords.value = connectors.value.length
  } catch (error) {
    console.error('Error retrieving locations:', error)
  } finally {
    loading.value = false
  }
}

const getConnectorsTypes = async () => {
  try {
    connectorTypes.value = await ApiService.readAllEntities(RouteNamespace.connectorsTypes)
  } catch (error) {
    console.error('Error retrieving connector types:', error)
  } finally {
    loading.value = false
  }
}

const getRates = async () => {
  try {
    loading.value = true
    rates.value = await ApiService.readAllEntities(RouteNamespace.rates)
  } catch (error) {
    console.error('Error retrieving rates:', error)
  } finally {
    loading.value = false
  }
}

const handleSelection = (event: Event) => {
  enabled.value = true
  connectorSelected.value = event['data']
}

const updateCodeNumbersSwitchAvailability = (availabilities: ActionRequested[]) => {
  availabilities.forEach((availability) => {
    if (
      availability.statusRequest === StatusRequest.PENDING &&
      availability.target === AvailabilityType.INOPERATIVE
    )
      changeAvailabilitySwitch.value[availability.connectorId] = 2
    else {
      const connectorStatus = chargePointStatusStore.getChargePointStatusByConnector(
        availability.cpId,
        availability.connectorId
      )
      changeAvailabilitySwitch.value[availability.connectorId] =
        connectorStatus.status === ChargePointStatus.UNAVAILABLE ? 0 : 1
    }
  })
}

watch(
  () => responsesOcppStore.actionRequested,
  (newValues) => {
    const actionRequested = Object.values(newValues).filter(
      (newValue) => newValue.cpId === cpId.value
    )
    const changeAvailability = actionRequested.filter(
      (connector) => connector.command === TopicNameSpace.changeAvailability
    )
    if (changeAvailability.length > 0) updateCodeNumbersSwitchAvailability(changeAvailability)
  },
  { deep: true }
)
watch(
  () => responsesOcppStore.unlockConnector,
  (newValues) => {
    Object.values(newValues).forEach((newValue) => {
      if (!newValue) return
      if (newValue.payload.status === UnlockStatus.UNLOCK_FAILED) {
        unlockConnectorDisabled.value[newValue.request.connectorId - 1] = false
      }
    })
  },
  { deep: true }
)
watch(
  () => meterValuesStore.meterValues,
  (newValues) => {
    if (!cpId.value) return
    try {
      const newMeterValue = Object.values(newValues).filter((newValue) => {
        if (newValue.cpId === cpId.value) {
          const status = chargePointStatusStore.getChargePointStatusByConnector(
            newValue.cpId,
            newValue.connectorId
          )
          if (status?.status === ChargePointStatus.CHARGING) return newValue
        }
      })
      newMeterValue.forEach((meterValue) => getPointToDraw(meterValue))
    } catch (err) {
      console.error('Failed to register the new meterValue', err)
    }
  },
  { deep: true }
)

watch(
  () => chargerConnectionStatusStore.chargersConnectionStatus,
  (newValues) => {
    try {
      for (const key in newValues) {
        const value = newValues[key]
        if (value === ChargerWsStatus.DISCONNECTED) {
          disableAll.value = true
          return
        }
      }
      disableAll.value = false
    } catch (err) {
      console.error('Failed to register the new connect/disconnect status', err)
    }
  },
  { deep: true }
)
watch(
  () => chargePointStatusStore.chargePointsStatuses,
  (newStatuses) => {
    const statusStation = Object.values(newStatuses).filter(
      (newStatus) => newStatus.cpId === cpId.value
    )
    statusStation.forEach((newValue) => {
      if (!newValue) return
      const connectorId = newValue.connectorId
      let codeStateSwitch = newValue.status === ChargePointStatus.UNAVAILABLE ? 0 : 1
      if (connectorId !== 0) {
        const key = `${cpId.value}:${connectorId}:${TopicNameSpace.changeAvailability}`
        const pendingUnavailability = responsesOcppStore.getActionRequestedByKey(key)
        if (pendingUnavailability) {
          if (
            pendingUnavailability.statusRequest === StatusRequest.PENDING &&
            pendingUnavailability.target === AvailabilityType.INOPERATIVE
          ) {
            codeStateSwitch = 2
          }
        }
      }
      changeAvailabilitySwitch.value[connectorId] = codeStateSwitch

      if (changeAvailabilityDisabled.value[connectorId]) {
        changeAvailabilityDisabled.value[connectorId] = false
      }

      if (connectorId > 0) {
        startChargerDisabled.value[connectorId - 1] = ![
          ChargePointStatus.AVAILABLE,
          ChargePointStatus.PREPARING
        ].includes(newValue.status)

        stopChargerDisabled.value[connectorId - 1] = ![
          ChargePointStatus.CHARGING,
          ChargePointStatus.SUSPENDED_EVSE,
          ChargePointStatus.SUSPENDED_EV
        ].includes(newValue.status)

        unlockConnectorDisabled.value[connectorId - 1] = [
          ChargePointStatus.UNAVAILABLE,
          ChargePointStatus.AVAILABLE
        ].includes(newValue.status)

        reserveNowDisabled.value[connectorId - 1] = newValue.status !== ChargePointStatus.AVAILABLE

        cancelReservationDisabled.value[connectorId - 1] =
          newValue.status !== ChargePointStatus.RESERVED
      }
      disableAll.value = false
    })
  },
  { deep: true }
)
onMounted(async () => {
  try {
    await Promise.all([getConnectors(), getConnectorsTypes(), getRates()])
    prepareRfidCards()
    initializeRefVariables()
    setChargerUI()
  } catch (error) {
    console.error('Error occurred while fetching data:', error)
  } finally {
    loading.value = false
  }
})
</script>

<template>
  <AppTopbar>
    <template #header>
      <div class="flex flex-column h-4rem text-3xl mb-3">
        <div>
          <Button
            class="text-500 hover:bg-teal-50"
            icon="pi pi-arrow-left"
            :label="`${t('dashboard.locations')} / ${route.params.name}`"
            text
            as="router-link"
            :to="`/${RouteNamespace.locations}/${route.params.id}`"
          />
        </div>
        <div class="flex flex-row ml-2 justify-content-between align-items-center">
          <div class="flex align-items-center">
            <span class="font-bold mr-2">{{ t('dashboard.chargePoint') }}</span>
            <span class="font-family-light font-italic alias">{{ capitalizeString(alias) }}</span>
          </div>
          <div class="flex flex-row align-items-center">
            <BaseInputSwitchRows
              :operative="changeAvailabilitySwitch.some((v) => v === 1) ? 1 : 0"
              :disabled="disableAll"
              @click="handleOCPPRequest(0, RouteNamespace.changeAvailability)"
            />
            <div class="ml-3 text-600 text-lg">
              {{
                disableAll
                  ? t('detail.location.status.inactive')
                  : changeAvailabilitySwitch.some((v) => v === 1)
                    ? t('detail.location.status.active')
                    : t('detail.location.status.unavailable')
              }}
            </div>
          </div>
        </div>
      </div>
    </template>
  </AppTopbar>
  <div class="card h-fit bg-white shadow-none">
    <DataTable
      v-model:expanded-rows="expandedRows"
      :value="connectors"
      :rowHover="true"
      @rowSelect="handleSelection"
      @rowExpand="showExpandedData"
      sortField="connection"
      :sortOrder="1"
      scrollable
    >
      <template #empty>
        <BaseLottieAnimation :icon="clock" :label="t('detail.connector.notFound')" />
      </template>
      <template #header>
        <div class="flex justify-content-between h-3rem">
          <div class="flex align-items-center justify-content-between">
            <svg-icon name="connector" size="24" color="#9E9E9E" />
            <span class="font-family-light ml-2 text-2xl">{{
              t('detail.connector.header.connectors')
            }}</span>
          </div>
        </div>
      </template>
      <Column expander class="table__expander" />
      <Column
        field="connection"
        :header="t('detail.connector.header.connector')"
        header-class="font-bold"
        class="table__connection"
      >
        <template #body="slotProps">
          <span>{{ slotProps['data']['connection'] }}</span>
        </template>
      </Column>
      <Column
        field="connectors.connectorType"
        :header="t('detail.connector.header.type')"
        header-class="font-bold"
        class="table__type"
      >
        <template #body="slotProps">
          <div class="flex align-items-center">
            <svg-icon
              :name="`${slotProps.data['connectorType']['connector']}`"
              size="24"
              color="#9E9E9E"
            />
            <span class="ml-2">{{
              slotProps.data['connectorType']['connector'] !== CHADEMO
                ? slotProps.data['connectorType']['connector'].toUpperCase()
                : slotProps.data['connectorType']['connector']
            }}</span>
          </div>
        </template>
      </Column>
      <Column
        field="connectorType.power"
        :header="t('detail.connector.header.powerNominal')"
        header-class="font-bold"
        class="table__power"
      >
        <template #body="slotProps">
          <span>{{ slotProps['data']['maxPower'] }} {{ UnitOfMeasure.KW }}</span>
        </template>
      </Column>
      <Column
        field="connectorType.energy"
        :header="t('detail.connector.header.energySupplied')"
        header-class="font-bold"
        class="table__energy"
      >
        <template #body="slotProps">
          <span>{{
            `${meterValuesStore.getSuppliedEnergy(slotProps.data.station.cpId, slotProps.data.connection)} ${UnitOfMeasure.KWH} `
          }}</span>
        </template>
      </Column>
      <Column
        field="status"
        :header="t('detail.connector.header.status')"
        header-class="font-bold"
        class="table__status"
      >
        <template #body="slotProps">
          <BaseBadge
            rounded
            outlined
            :style-header="`badgeStatus badgeStatus__${getClassForStatusStore(
              chargerConnectionStatusStore.getChargerConnectionStatusByCpId(
                slotProps.data.station.cpId
              ) === ChargerWsStatus.CONNECTED
                ? (chargePointStatusStore.getChargePointStatusByConnector(
                    slotProps.data.station.cpId,
                    slotProps.data.connection
                  )?.status ?? StationStatusMap.DISCONNECTED)
                : StationStatusMap.DISCONNECTED
            )}`"
            :style-content="`status status__${getClassForStatusStore(
              chargerConnectionStatusStore.getChargerConnectionStatusByCpId(
                slotProps.data.station.cpId
              ) === ChargerWsStatus.CONNECTED
                ? (chargePointStatusStore.getChargePointStatusByConnector(
                    slotProps.data.station.cpId,
                    slotProps.data.connection
                  )?.status ?? StationStatusMap.DISCONNECTED)
                : StationStatusMap.DISCONNECTED
            )}`"
            :content="
              getStatusTranslationStore(
                chargerConnectionStatusStore.getChargerConnectionStatusByCpId(
                  slotProps.data.station.cpId
                ) === ChargerWsStatus.CONNECTED
                  ? (chargePointStatusStore.getChargePointStatusByConnector(
                      slotProps.data.station.cpId,
                      slotProps.data.connection
                    )?.status ?? StationStatusMap.DISCONNECTED)
                  : StationStatusMap.DISCONNECTED
              )
            "
          />
        </template>
      </Column>
      <Column
        :header="t('detail.header.actions')"
        header-class="table__header font-bold"
        class="table__actions"
      >
        <template #body="slotProps">
          <div class="flex flex-row justify-content-center">
            <Button
              class="button button-start mr-2"
              v-tooltip.top="t('detail.connector.actions.startCharge')"
              rounded
              :disabled="disableAll || startChargerDisabled[slotProps.data.connection - 1]"
              @click="
                () => {
                  selectIdTagVisible = true
                  selectedNumberConnector = slotProps.data.connection
                }
              "
            >
              <template #icon>
                <svg-icon name="charge" size="20" color="white" />
              </template>
            </Button>
            <Button
              class="button button-cancel mr-2"
              v-tooltip.top="t('detail.connector.actions.stopCharge')"
              rounded
              :disabled="disableAll || stopChargerDisabled[slotProps.data.connection - 1]"
              @click="handleOCPPRequest(slotProps.data, RouteNamespace.remoteStopTransaction)"
            >
              <template #icon>
                <svg-icon name="cancel-charge" size="20" />
              </template>
            </Button>
            <BaseConfirmDeletePopup ref="popup" />
            <Button
              class="button button-warning mr-2"
              v-tooltip.top="t('detail.connector.actions.unblockPoint')"
              rounded
              :disabled="disableAll || unlockConnectorDisabled[slotProps.data.connection - 1]"
              @click="handleOCPPRequest(slotProps.data, RouteNamespace.unlockConnector)"
            >
              <template #icon>
                <svg-icon name="disconnected" size="20" color="#2C2C2C" />
              </template>
            </Button>
            <Button
              class="button button-reserve-outlined mr-2"
              :class="slotProps.data.station.charger.name !== 'genesis' ? 'hidden' : ''"
              v-tooltip.top="t('detail.connector.actions.reserveNow')"
              rounded
              :disabled="disableAll || reserveNowDisabled[slotProps.data.connection - 1]"
              @click="
                () => {
                  selectExpiryDateVisible = true
                  selectedNumberConnector = slotProps.data.connection
                }
              "
            >
              <template #icon>
                <svg-icon name="calendar" size="18" color="#EA2839" />
              </template>
            </Button>
            <Button
              class="button button-reserve"
              :class="slotProps.data.station.charger.name !== 'genesis' ? 'hidden' : ''"
              v-tooltip.top="t('detail.connector.actions.cancelReservation')"
              rounded
              :disabled="disableAll || cancelReservationDisabled[slotProps.data.connection - 1]"
              @click="handleOCPPRequest(slotProps.data, RouteNamespace.cancelReservation)"
            >
              <template #icon>
                <svg-icon name="calendar" size="18" color="#FFF" />
              </template>
            </Button>
          </div>
        </template>
      </Column>
      <template #expansion="slotProps">
        <div class="flex flex-row col-12">
          <div class="col-6 chart-container">
            <BaseChart
              :id="`${slotProps['data']['station'].cpId}:${slotProps['data'].connection}`"
              :key="`${slotProps['data']['station'].cpId}:${slotProps['data'].connection}`"
              :type="chartType"
              :data="dataCurrent[slotProps['data']['connection'] - 1]"
              :autosize="true"
              :chart-options="{ ...chartOptions, ...priceScaleOptionCurrent }"
              :series-options="seriesOptions"
              :time-scale-options="timeScaleOptions"
              ref="lwChartCurrent"
            />
            <span class="font-italic text-500">{{
              t('detail.connector.charts.energySupplied')
            }}</span>
          </div>
          <div class="col-6 chart-container">
            <BaseChart
              :type="chartType"
              :data="dataPower[slotProps['data']['connection'] - 1]"
              :autosize="true"
              :chart-options="{ ...chartOptions, ...priceScaleOptionPower }"
              :series-options="seriesOptions"
              :time-scale-options="timeScaleOptions"
              ref="lwChartPower"
            />
            <span class="font-italic text-500">{{
              t('detail.connector.charts.counterMeasure')
            }}</span>
          </div>
        </div>
        <div class="flex flex-row p-4 align-items-center">
          <div class="flex flex-row align-items-center w-4">
            <BaseInputSwitchRows
              :operative="changeAvailabilitySwitch[slotProps.data.connection]"
              :disabled="disableAll"
              @click="
                handleOCPPRequest(slotProps.data.connection, RouteNamespace.changeAvailability)
              "
            />
            <span class="font-bold text-500 ml-3">
              {{
                disableAll
                  ? t('detail.location.status.inactive')
                  : changeAvailabilitySwitch[slotProps.data.connection] === 1
                    ? t('detail.location.status.active')
                    : changeAvailabilitySwitch[slotProps.data.connection] === 0
                      ? t('detail.location.status.unavailable')
                      : t('detail.location.status.scheduled')
              }}</span
            >
          </div>
        </div>
      </template>
    </DataTable>
  </div>
  <BaseDialog v-model:visible="selectIdTagVisible" :style="{ width: '30vw' }">
    <template #title>
      <div class="absolute top-0 left-0 mt-4 mb-4 ml-3">
        <div class="flex flex-row">
          <p class="p-dialog-title mr-1">
            {{ t('detail.connector.cards.dialog.placeholder') }}
          </p>
          <p class="p-dialog-title font-family-light font-italic">
            {{ `${t('detail.connector.header.connector')} ${selectedNumberConnector}` }}
          </p>
        </div>
      </div>
    </template>
    <template #header>
      <div class="absolute top-0 right-0 mt-4 mr-3">
        <svg-icon name="rfid-card" size="24" color="white" />
      </div>
    </template>
    <template #body>
      <div class="flex flex-row items-center gap-5 mt-2 mb-4">
        <div class="field col-12">
          <Select
            v-model="selectedIdTag"
            :options="rfIds"
            :optionLabel="(data) => `${data.alias} - idTag: ${data.idTag}`"
            optionValue="id"
            :placeholder="t('detail.connector.cards.dialog.placeholder')"
            :emptyFilterMessage="t('detail.card.notFound')"
            :emptyMessage="t('detail.card.notFound')"
            checkmark
            filter
            :highlightOnSelect="false"
            :pt="{
              item: ({ props, state, context }) => ({
                class: context.selected
                  ? 'bg-gray-300'
                  : context.focused
                    ? 'bg-gray-100'
                    : undefined
              })
            }"
          >
            <template #dropdownicon>
              <div class="flex flex-column justify-content-center p-0 col-12">
                <svg-icon name="arrow-down" size="18" color="#E9E9E9" />
              </div>
            </template>
          </Select>
        </div>
      </div>
    </template>
    <template #footer>
      <div class="flex justify-content-center gap-2">
        <Button
          type="button"
          :label="t('detail.connector.cards.dialog.button.send')"
          rounded
          class="button button-save size mr-3"
          :disabled="!selectedIdTag"
          @click="handleOCPPRequest(selectedNumberConnector, RouteNamespace.remoteStartTransaction)"
        ></Button>
        <Button
          type="button"
          :label="t('actions.cancel')"
          rounded
          class="button button-cancel size"
          @click="
            () => {
              selectIdTagVisible = false
              selectedIdTag = ''
            }
          "
        ></Button>
      </div>
    </template>
  </BaseDialog>
  <!--TODO WIP -->
  <BaseDialog v-model:visible="selectExpiryDateVisible" :style="{ width: '30vw' }">
    <template #title>
      <div class="absolute top-0 left-0 mt-4 mb-4 ml-3">
        <div class="flex flex-row">
          <p class="p-dialog-title mr-1">
            {{ t('detail.connector.cards.dialog.placeholder') }}
          </p>
          <p class="p-dialog-title font-family-light font-italic">
            {{ `${t('detail.connector.header.connector')} ${selectedNumberConnector}` }}
          </p>
        </div>
      </div>
    </template>
    <template #header>
      <div class="absolute top-0 right-0 mt-4 mr-3">
        <svg-icon name="rfid-card" size="24" color="white" />
      </div>
    </template>
    <template #body>
      <div class="flex flex-column items-center gap-5 mt-2">
        <div class="field col-12">
          <Select
            v-model="selectedIdTag"
            class="mb-4"
            :options="rfIds"
            :optionLabel="(data) => `${data.alias} - idTag: ${data.idTag}`"
            :emptyFilterMessage="t('detail.card.notFound')"
            filter
            optionValue="id"
            :placeholder="t('detail.connector.cards.dialog.placeholder')"
            :emptyMessage="t('detail.card.notFound')"
            checkmark
            :highlightOnSelect="false"
            :pt="{
              item: ({ props, state, context }) => ({
                class: context.selected
                  ? 'bg-gray-300'
                  : context.focused
                    ? 'bg-gray-100'
                    : undefined
              })
            }"
          >
            <template #dropdownicon>
              <div class="flex flex-column justify-content-center p-0 col-12">
                <svg-icon name="arrow-down" size="18" color="#E9E9E9" />
              </div>
            </template>
          </Select>
          <DatePicker
            v-model="selectExpiryDate"
            class="shadow-none"
            :showIcon="true"
            showTime
            showButtonBar
            hourFormat="24"
            :minDate="new Date()"
            fluid
          />
        </div>
      </div>
    </template>
    <template #footer>
      <div class="flex justify-content-center gap-2">
        <Button
          type="button"
          :label="t('detail.connector.cards.dialog.button.send')"
          rounded
          class="button button-save size mr-3"
          :disabled="!selectExpiryDate"
          @click="handleOCPPRequest(selectedNumberConnector, RouteNamespace.reserveNow)"
        ></Button>
        <Button
          type="button"
          :label="t('actions.cancel')"
          rounded
          class="button button-cancel size"
          @click="
            () => {
              selectExpiryDateVisible = false
            }
          "
        />
      </div>
    </template>
  </BaseDialog>
  <BasePushNotification
    :title="t('notifications.rechargeStarted')"
    icon-name="connected"
    color="#00DB7F"
    :group="RouteNamespace.remoteStartTransaction"
    severity="success"
    :summary="toastSummary"
  />
  <BasePushNotification
    :title="t('notifications.rechargeStopped')"
    icon-name="disconnected"
    color="#EA2839"
    :group="RouteNamespace.remoteStopTransaction"
    severity="error"
    :summary="t('notifications.rechargeStopped')"
  />
  <BasePushNotification
    :title="t('notifications.unlockConnector')"
    icon-name="disconnected"
    color="#FFC700"
    :group="RouteNamespace.unlockConnector"
    severity="warn"
    :summary="t('notifications.unlockConnector')"
  />
  <BasePushNotification
    :title="t('notifications.reserveNow')"
    icon-name="connected"
    color="#FFC700"
    :group="RouteNamespace.reserveNow"
    severity="warn"
    :summary="t('notifications.reserveNow')"
  />
  <BasePushNotification
    :title="t('notifications.cancelReservation')"
    icon-name="disconnected"
    color="#EA2839"
    :group="RouteNamespace.cancelReservation"
    severity="error"
    :summary="t('notifications.cancelReservation')"
  />
  <Toast group="connectors" />
</template>
<style scoped lang="scss">
.hidden {
  visibility: hidden;
}

::v-deep(.p-inputswitch ::before) {
  background-color: var(--white) !important;
}

::v-deep(.p-inputswitch-checked ::before) {
  background-color: var(--action-activate) !important;
}

::v-deep(.table__header) > div > span {
  margin: 0 auto;
}

::v-deep(.table__expander) {
  width: 2%;
}

::v-deep(.table__connection) {
  width: 5%;
}

::v-deep(.table__type) {
  width: 5%;
}

::v-deep(.table__power) {
  width: 8%;
}

::v-deep(.table__energy) {
  width: 10%;
}

::v-deep(.table__status) {
  width: 10%;
}

::v-deep(.table__actions) {
  width: 5%;
}
</style>
