export const capitalizeString = (input: string | null): string => {
  if (!input || input.trim().length === 0) return ''

  const upperCaseTerms = ['SL', 'SA', 'SLU']

  return input
    .trim()
    .split(/\s+/)
    .map((word) => {
      const wordWithoutComma = word.replace(',', '')
      if (upperCaseTerms.includes(wordWithoutComma.toUpperCase())) {
        return wordWithoutComma.toUpperCase() + (word.includes(',') ? ',' : '')
      }
      return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
    })
    .join(' ')
}
