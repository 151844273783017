<script setup lang="ts">
import { computed, onMounted, ref, watch } from 'vue'
import { t } from '@/common/i18n'
import type { IndexedSchedule, TimeRange } from '@/models/domain/rate/api/Rate.ts'

const { updatedSchedule } = defineProps<{
  updatedSchedule?: IndexedSchedule
}>()

const emit = defineEmits<{
  (e: 'updateTimeRange', newTimeRange: TimeRange): void
}>()

const start = ref()
const end = ref()

const startMaxDate = computed(() => {
  const maxDate = new Date(end.value)
  maxDate.setMinutes(maxDate.getMinutes() - 1)
  return maxDate
})
const endMinDate = computed(() => {
  const minDate = new Date(start.value)
  minDate.setMinutes(minDate.getMinutes() + 1)
  return minDate
})

watch([start, end], () => {
  emit('updateTimeRange', { start: start.value, end: end.value })
})

onMounted(() => {
  if (updatedSchedule) {
    start.value = updatedSchedule.timeRange.start
    end.value = updatedSchedule.timeRange.end
  }
})
</script>

<template>
  <div class="flex flex-row justify-content-between p-0 col-12">
    <div class="field col-6">
      <label class="font-family-light required" for="start">{{
        t('detail.rate.header.start')
      }}</label>
      <DatePicker
        v-model="start"
        id="start"
        class="shadow-none"
        hourFormat="24"
        timeOnly
        :manualInput="false"
        :maxDate="startMaxDate"
        :placeholder="t('detail.rate.dialog.placeholder.start')"
      />
    </div>
    <div class="field col-6">
      <label class="font-family-light required" for="end">{{ t('detail.rate.header.end') }}</label>
      <DatePicker
        v-model="end"
        id="end"
        class="shadow-none"
        hourFormat="24"
        timeOnly
        :manualInput="false"
        :minDate="endMinDate"
        :placeholder="t('detail.rate.dialog.placeholder.end')"
      />
    </div>
  </div>
</template>

<style scoped></style>
