import { ChargePointStatus, WebSocketStatus } from '@/models/ocpp/enums.ts'

export interface Cluster {
  getChildCount(): number
}

// TODO: Align STATES and COLORS with UPDATED_STATES and UPDATED_COLORS
export type State = 'available' | 'unavailable' | 'suspended' | 'disconnected'
export const STATES: Record<State, State> = {
  available: 'available',
  unavailable: 'unavailable',
  suspended: 'suspended',
  disconnected: 'disconnected'
}

export const COLORS: Record<State, string> = {
  available: '#00DB7F',
  unavailable: '#FB1626',
  suspended: '#FFC107',
  disconnected: '#626868'
}

export const UPDATED_STATES: Record<ChargePointStatus | WebSocketStatus, string> = {
  [ChargePointStatus.AVAILABLE]: 'available',
  [ChargePointStatus.CHARGING]: '',
  [ChargePointStatus.FAULTED]: 'faulted',
  [ChargePointStatus.FINISHING]: '',
  [ChargePointStatus.PREPARING]: '',
  [ChargePointStatus.RESERVED]: '',
  [ChargePointStatus.SUSPENDED_EV]: '',
  [ChargePointStatus.SUSPENDED_EVSE]: '',
  [ChargePointStatus.UNAVAILABLE]: 'unavailable',
  [WebSocketStatus.CONNECTED]: '',
  [WebSocketStatus.DISCONNECTED]: 'disconnected'
}

export const UPDATED_COLORS: Record<ChargePointStatus | WebSocketStatus, string> = {
  [ChargePointStatus.AVAILABLE]: '#00DB7F',
  [ChargePointStatus.CHARGING]: '',
  [ChargePointStatus.FAULTED]: '#FFC107',
  [ChargePointStatus.FINISHING]: '',
  [ChargePointStatus.PREPARING]: '',
  [ChargePointStatus.RESERVED]: '',
  [ChargePointStatus.SUSPENDED_EV]: '',
  [ChargePointStatus.SUSPENDED_EVSE]: '',
  [ChargePointStatus.UNAVAILABLE]: '#FB1626',
  [WebSocketStatus.CONNECTED]: '',
  [WebSocketStatus.DISCONNECTED]: '#626868'
}

export const attribution =
  '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a>'
