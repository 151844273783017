<script setup lang="ts">
import type { PageState } from 'primevue/paginator'

const { rowsPerPageOptions = [10, 50, 100], totalRecords } = defineProps<{
  rowsPerPageOptions?: number[]
  totalRecords: number
}>()

const currentPage = defineModel<number>('currentPage')
const rowsPerPage = defineModel<number>('rowsPerPage')

const emit = defineEmits<{
  (e: 'refreshData'): void
}>()

const onPageChange = (event: PageState) => {
  currentPage.value = event.page
  rowsPerPage.value = event.rows
  emit('refreshData')
}
</script>

<template>
  <Paginator
    class="flex justify-content-center"
    :rows="rowsPerPage"
    :rowsPerPageOptions
    :totalRecords
    :pt="{
      pageButton: ({ props, state, context }) => ({
        class: context.active ? 'bg-gray-500 text-white' : undefined
      })
    }"
    @page="onPageChange"
  />
</template>
