<script setup lang="ts">
import { computed, onMounted, ref } from 'vue'
import { storeToRefs } from 'pinia'
import { FilterMatchMode } from '@primevue/core/api'
import { useToast } from 'primevue/usetoast'
import clock from '@/assets/lottie/clock.json'
import { t } from '@/common/i18n'
import SvgIcon from '@/common/icons/SvgIcon.vue'
import BaseBadge from '@/components/ui/BaseBadge.vue'
import BaseConfirmDeletePopup from '@/components/ui/BaseConfirmDeletePopup.vue'
import BaseLottieAnimation from '@/components/ui/BaseLottieAnimation.vue'
import BasePaginator from '@/components/ui/BasePaginator.vue'
import BasePushNotification from '@/components/ui/BasePushNotification.vue'
import RateCreationDialog from '@/components/rates/RateCreationDialog.vue'
import AppTopbar from '@/layout/AppTopbar.vue'
import { RouteNamespace } from '@/models/common/RouteNameSpace'
import type { Rate, RateResponse } from '@/models/domain/rate/api/Rate'
import { ApiService } from '@/services/ApiService'
import { useApi } from '@/stores/api/api'
import { capitalizeString } from '@/utils/capitalize'
import { getStatusEnabledHours } from '@/utils/status'

const toast = useToast()

const { loading } = storeToRefs(useApi())

const activeRateCreationDialog = ref<boolean>(false)
const activeRateUpdateDialog = ref<boolean>(false)
const popup = ref()
const updatedRate = ref<Rate>()
const rates = ref([])
const statusRangeHours = ref({
  0: t('status.enabled'),
  1: t('status.disabled')
})

const filters = ref({
  global: { value: undefined, matchMode: FilterMatchMode.CONTAINS }
})
const currentPage = ref<number>(0)
const rowsPerPage = ref<number>(10)
const totalRecords = ref<number>(0)

const evaluateRangeHours = computed(
  () =>
    (rangeHours: number): string =>
      statusRangeHours.value[rangeHours] || ''
)

const getRates = async () => {
  try {
    const result = await ApiService.readAllEntities<RateResponse>(
      `${RouteNamespace.rates}?limit=${rowsPerPage.value}&offset=${currentPage.value}`
    )
    rates.value = result['rates']
    totalRecords.value = result['totalRecords']
  } catch (error) {
    console.log(error)
  }
}

const handleCreateRate = () => {
  activeRateCreationDialog.value = true
}

const handleUpdateRate = (rowData: Rate) => {
  updatedRate.value = {
    ...rowData
  }
  activeRateUpdateDialog.value = true
}

const handleRemoveRate = async (event: Event, id: string) => {
  popup.value.showConfirmPopup(
    event,
    async () => {
      try {
        loading.value = true
        await ApiService.deleteEntity(`${RouteNamespace.rates}/${id}`)
        toast.add({
          group: 'success',
          severity: 'success',
          summary: t('detail.rate.notifications.deleteSuccess'),
          life: 3000
        })
      } catch (error) {
        console.error('Error deleting rate:', error)
      } finally {
        await getRates()
        loading.value = false
      }
    },
    undefined
  )
}

onMounted(async () => {
  try {
    loading.value = true
    await Promise.all([getRates()])
    // items.value[1].label = alias.value
  } catch (error) {
    console.error('Error retrieving charge points:', error)
  } finally {
    loading.value = false
  }
})
</script>
<template>
  <AppTopbar>
    <template #header>
      <div class="flex flex-column h-4rem text-3xl">
        <div class="flex flex-row ml-2 justify-content-between align-items-center">
          <div class="flex">
            <svg-icon name="rate" size="24" color="#626868" />
            <span class="font-bold ml-2 text-2xl">{{ t('dashboard.rates') }}</span>
          </div>
          <Button
            v-tooltip.top="t('detail.rate.actions.create')"
            class="button button-normal ml-3"
            rounded
            @click="handleCreateRate"
          >
            <template #icon>
              <svg-icon name="add" size="18" color="#626868" />
            </template>
          </Button>
        </div>
      </div>
    </template>
    <template #body>
      <div class="flex w-3">
        <IconField icon-position="left">
          <InputIcon class="pi pi-search"></InputIcon>
          <InputText
            v-model="filters['global'].value"
            :placeholder="t('search')"
            type="text"
            :pt="{
              root: {
                class: ['border-300']
              }
            }"
          />
        </IconField>
      </div>
    </template>
  </AppTopbar>
  <div class="card h-fit bg-white shadow-none">
    <DataTable
      v-model:filters="filters"
      :value="rates"
      :rows="rowsPerPage"
      :globalFilterFields="['alias', 'rateType']"
      dataKey="id"
      scrollable
    >
      <template #empty>
        <BaseLottieAnimation :icon="clock" :label="t('detail.rate.notFound')" />
      </template>
      <Column
        field="rate"
        :header="t('detail.rate.header.rate')"
        header-class="font-bold"
        class="table__rate"
      >
        <template #body="slotProps">
          {{ `${capitalizeString(slotProps['data']['alias'])}` }}
        </template>
      </Column>
      <Column
        field="rateType"
        :header="t('detail.rate.header.mode')"
        header-class="font-bold"
        class="table__type"
      >
        <template #body="slotProps">
          {{ `${capitalizeString(slotProps['data']['rateType'])}` }}
        </template>
      </Column>
      <Column
        field="mode"
        :header="t('detail.rate.header.cost')"
        header-class="font-bold"
        class="table__cost"
      >
        <template #body="slotProps">
          {{
            `${slotProps['data']['flatRate'].toFixed(2)} ${slotProps['data']['currency']} /
              ${slotProps['data']['unit']}`
          }}
        </template>
      </Column>
      <Column
        field="stations"
        :header="t('detail.rate.header.stations')"
        header-class="font-bold"
        class="table__chargers"
      >
        <template #body="slotProps">
          {{ slotProps.data['stationCount'] }}
        </template>
      </Column>
      <Column
        field="range"
        :header="t('detail.rate.header.range')"
        header-class="font-bold"
        class="table__status"
      >
        <template #body="slotProps">
          <BaseBadge
            type="button"
            rounded
            outlined
            :style-header="`badgeStatus badgeStatus__${getStatusEnabledHours(slotProps.data['schedule'])}`"
            :style-content="`status status__${getStatusEnabledHours(slotProps.data['schedule'])}`"
            :content="`${evaluateRangeHours(slotProps.data['schedule'])}`"
          />
        </template>
      </Column>
      <Column
        :header="t('detail.header.actions')"
        header-class="table__header font-bold"
        class="table__actions"
      >
        <template #body="slotProps">
          <div class="flex flex-row justify-content-center">
            <Button
              class="button button-normal mr-2"
              v-tooltip.top="t('detail.rate.actions.update')"
              rounded
              @click="handleUpdateRate(slotProps.data)"
            >
              <template #icon>
                <svg-icon name="edit" size="20" color="#626868" />
              </template>
            </Button>
            <BaseConfirmDeletePopup ref="popup" />
            <Button
              class="button button-remove"
              v-tooltip.top="t('detail.rate.actions.delete')"
              rounded
              @click="handleRemoveRate($event, slotProps.data.id)"
            >
              <template #icon>
                <svg-icon name="trash" size="18" />
              </template>
            </Button>
          </div>
        </template>
      </Column>
      <template #footer>
        <BasePaginator
          v-model:current-page="currentPage"
          v-model:rows-per-page="rowsPerPage"
          :totalRecords
          @refresh-data="getRates"
        />
      </template>
    </DataTable>
  </div>
  <RateCreationDialog
    v-model:visible="activeRateCreationDialog"
    :toasting="toast"
    @refresh-rates="getRates()"
  />
  <RateCreationDialog
    v-model:visible="activeRateUpdateDialog"
    updating
    :updated-rate="updatedRate"
    :toasting="toast"
    @refresh-rates="getRates()"
  />
  <BasePushNotification group="success" icon-name="restart" color="#00DB7F" />
</template>

<style scoped>
::v-deep(.table__header) > div > span {
  margin: 0 auto;
}

/* Rate table */

::v-deep(.table__rate) {
  width: 19%;
}

::v-deep(.table__type) {
  width: 16%;
}

::v-deep(.table__cost) {
  width: 14%;
}

::v-deep(.table__chargers) {
  width: 14%;
}

::v-deep(.table__status) {
  width: 20%;
}

::v-deep(.table__actions) {
  width: 11%;
}

/* Rate creation table */

::v-deep(.timetable__time) {
  width: 14%;
}

::v-deep(.timetable__cost) {
  width: 18%;
}

::v-deep(.timetable__start) {
  width: 12%;
}

::v-deep(.timetable__end) {
  width: 12%;
}

::v-deep(.timetable__range) {
  width: 25%;
}

::v-deep(.timetable__actions) {
  width: 19%;
}
</style>
