<script setup lang="ts">
import { computed, onMounted, ref, watch } from 'vue'
import AppFooter from './AppFooter.vue'
import AppSidebar from './AppSidebar.vue'
import { useLayout } from '@/layout/composables/layout'
import { useApi } from '@/stores/api/api'
import loadingIcon from '@/assets/lottie/loading.json'
import { Vue3Lottie } from 'vue3-lottie'
import { storeToRefs } from 'pinia'
import { socket, state } from '@/config/socket'
import { useAuthStore } from '@/stores/auth'
import { useTransactions } from '@/stores/api/transactions'
import { type availableLanguages, i18n } from '@/common/i18n'
import {
  useChargerConnectionStatusStore,
  useMeterValues,
  useResponsesOcpp,
  useChargePointStatusStore
} from '@/stores/ocpp'
import { Roles } from '@/models'
import { Action } from '@/models/ocpp/enums'
import { TopicNameSpace } from '@/models/common/TopicNameSpace'

const { loading } = storeToRefs(useApi())
const language = ref<availableLanguages>('es')
const { layoutConfig, layoutState, isSidebarActive } = useLayout()
const outsideClickListener = ref({})
const apiStore = useApi()
const authStore = useAuthStore()
const roles = ref([])
const transactions = ref([])
const connectorTypes = ref([])
const chargers = ref([])
const userLocation = ref({ timezone: '' })
const customerEmails = ref([])

const initWsAndStores = () => {
  //add customerId to socket query for ocpp broadcast
  socket.io.opts.query = {
    userId: authStore.userId
  }

  //initialize ocpp-csms stores
  const meterValuesStore = useMeterValues()
  const chargePointStatusStore = useChargePointStatusStore()
  const responsesOcppStore = useResponsesOcpp()
  const chargerConnectionStatusStore = useChargerConnectionStatusStore()
  const responsesOCPPStore = useResponsesOcpp()
  // crud context stores
  const transactionsStore = useTransactions()

  //bind socket.io-stores events
  meterValuesStore.bindEvents()
  chargePointStatusStore.bindEvents()
  responsesOcppStore.bindEvents()
  chargerConnectionStatusStore.bindEvents()
  responsesOCPPStore.bindEvents()
  transactionsStore.bindEvents()

  //initialize the context event to set last values in stores
  socket.on('context', (data) => {
    for (const d of data) {
      switch (d.action) {
        case Action.METER_VALUES:
          meterValuesStore.setMeterValues(d.cpId, d.payload.connectorId, d.payload)
          break
        case Action.STATUS_NOTIFICATION:
          chargePointStatusStore.setChargePointStatus(d.cpId, d.payload.connectorId, d.payload)
          break
        case Action.WEBSOCKET_STATUS:
          chargerConnectionStatusStore.setChargerConnectionStatus(d.cpId, d.status)
          break
        case TopicNameSpace.actionRequested:
          responsesOcppStore.setActionRequested(d.cpId, d.connectorId, d.command, d)
          break
      }
    }
  })

  socket.connect()
}
watch(isSidebarActive, (newVal) => {
  newVal ? bindOutsideClickListener() : unbindOutsideClickListener()
})

const containerClass = computed(() => {
  return {
    'layout-theme-light': layoutConfig.darkTheme.value,
    'layout-overlay': layoutConfig.menuMode.value === 'overlay',
    'layout-static': layoutConfig.menuMode.value === 'static',
    'layout-static-inactive':
      layoutState.staticMenuDesktopInactive.value && layoutConfig.menuMode.value === 'static',
    'layout-overlay-active': layoutState.overlayMenuActive.value,
    'layout-mobile-active': layoutState.staticMenuMobileActive.value,
    'p-input-filled': layoutConfig.inputStyle.value === 'filled'
  }
})
const bindOutsideClickListener = () => {
  if (!outsideClickListener.value) {
    outsideClickListener.value = (event: unknown) => {
      if (isOutsideClicked(event)) {
        layoutState.overlayMenuActive.value = false
        layoutState.staticMenuMobileActive.value = false
        layoutState.menuHoverActive.value = false
      }
    }
    document.addEventListener('click', outsideClickListener.value as (ev: MouseEvent) => void)
  }
}
const unbindOutsideClickListener = () => {
  if (outsideClickListener.value) {
    document.removeEventListener('click', outsideClickListener.value as (ev: MouseEvent) => void)
    outsideClickListener.value = {}
  }
}
const isOutsideClicked = (event) => {
  const sidebarEl = document.querySelector('.layout-sidebar')
  const topbarEl = document.querySelector('.layout-menu-button')

  return !(
    sidebarEl?.isSameNode(event.target) ||
    sidebarEl?.contains(event.target) ||
    topbarEl?.isSameNode(event.target) ||
    topbarEl?.contains(event.target)
  )
}
const fetchDataToStore = async (getter, fetcher, setter) => {
  try {
    setter.value = getter.length === 0 ? await fetcher() : getter
  } catch (error) {
    console.error(`Error retrieving data:`, error)
  }
}

const requestGeolocation = () => {
  if (navigator.geolocation) {
    navigator.geolocation.getCurrentPosition(
      () => {
        userLocation.value = {
          timezone: Intl.DateTimeFormat().resolvedOptions().timeZone
        }
        sessionStorage.setItem(
          'timezone',
          userLocation.value.timezone ? userLocation.value.timezone : ''
        )
      },
      (error) => {
        console.error('Error getting geolocation:', error)
      }
    )
  } else {
    console.error('Geolocation is not supported by this browser.')
  }
}
onMounted(async () => {
  try {
    await Promise.all([
      fetchDataToStore(apiStore.getChargers, apiStore.fetchChargers, chargers),
      fetchDataToStore(apiStore.getRoles, apiStore.fetchRoles, roles),
      fetchDataToStore(apiStore.getTransactions, apiStore.fetchTransactions, transactions),
      fetchDataToStore(apiStore.getConnectorTypes, apiStore.fetchConnectorTypes, connectorTypes),
      authStore.role.name === Roles.admin
        ? fetchDataToStore(
            apiStore.getCustomerEmails,
            apiStore.fetchOrganizationEmails,
            customerEmails
          )
        : null
    ])

    initWsAndStores()
    requestGeolocation()
    i18n.global.locale =
      (sessionStorage.getItem('language') as availableLanguages) || language.value
  } catch (error) {
    console.error('Error occurred while fetching data:', error)
  }
})
</script>

<template>
  <div class="layout-wrapper" :class="containerClass">
    <div class="layout-sidebar">
      <AppSidebar />
    </div>
    <div class="layout-main-container">
      <div class="layout-main mt-3">
        <Transition>
          <div class="progress-spinner" v-if="loading">
            <Vue3Lottie :animationData="loadingIcon" height="100%" width="100%" />
          </div>
        </Transition>
        <Transition name="slide-fade" mode="in-out">
          <div v-if="state.connected">
            <router-view />
          </div>
        </Transition>
      </div>
    </div>
    <AppFooter />
  </div>
</template>
