<script setup lang="ts">
import { onMounted, ref } from 'vue'
import { storeToRefs } from 'pinia'
import { useToast } from 'primevue/usetoast'
import { FilterMatchMode } from '@primevue/core/api'
import { utils, write } from 'xlsx'
import { dateFormat, t } from '@/common/i18n'
import { RouteNamespace } from '@/models/common/RouteNameSpace'
import { ApiService } from '@/services/ApiService'
import BaseLottieAnimation from '@/components/ui/BaseLottieAnimation.vue'
import { evaluateStatus, getCauseEnding, getIconForCauseEnding } from '@/utils/status'
import { useApi } from '@/stores/api/api'
import clock from '@/assets/lottie/clock.json'
import BasePushNotification from '@/components/ui/BasePushNotification.vue'
import {
  type Transaction,
  type TransactionResponse,
  TypeDocument
} from '@/models/domain/transaction/api/Transaction'
import AppTopbar from '@/layout/AppTopbar.vue'
import SvgIcon from '@/common/icons/SvgIcon.vue'
import { useTransactions } from '@/stores/api/transactions'
import { UnitOfMeasure } from '@/models/ocpp/enums'
import { Roles } from '@/models'
import { useAuthStore } from '@/stores/auth'
import { capitalizeString } from '@/utils/capitalize.ts'
import BasePaginator from '@/components/ui/BasePaginator.vue'

const toast = useToast()
const transactionsStore = useTransactions()
const { loading } = storeToRefs(useApi())
const { role } = storeToRefs(useAuthStore())
const { transactions } = storeToRefs(transactionsStore)
const totalRecords = ref<number>(0)
const rowsPerPage = ref<number>(10)
const currentPage = ref<number>(0)
const expandedRows = ref([])
const transactionDates = ref<Date | Date[] | (Date | null)[] | null | undefined>(null)
const selectedProduct = ref()
const csv = ref()
const exportCSV = () => {
  csv.value.exportCSV()
}
const exportToExcel = () => {
  const data = transactions.value.map((transaction: Transaction) => {
    return {
      [t('detail.transactions.header.cost')]: `${transaction.cost} ${transaction.currency}`,
      [t('detail.transactions.header.start')]: new Date(transaction.createdAt).toLocaleString(),
      [t('detail.transactions.header.duration')]: handleDuration(parseInt(transaction.duration)),
      [t('detail.transactions.header.energySupplied')]:
        `${(transaction.energy / 1000).toFixed(2)} ${UnitOfMeasure.KWH}`,
      [t('detail.transactions.header.chargePoint')]: transaction.station.alias,
      [t('detail.transactions.header.connector')]: transaction.connector?.connection,
      [t('detail.transactions.header.cause_ending')]: evaluateStatus(transaction.causeEnding)
    }
  })
  const worksheet = utils.json_to_sheet(data)
  const workbook = utils.book_new()
  utils.book_append_sheet(workbook, worksheet, t('sidebar.transactions'))

  const excelBuffer = write(workbook, { bookType: 'xlsx', type: 'array' })
  const blob = new Blob([excelBuffer], { type: 'application/octet-stream' })
  const url = window.URL.createObjectURL(blob)
  const link = document.createElement('a')
  link.href = url
  link.setAttribute('download', `transactions-report-${new Date().toLocaleDateString()}.xlsx`)
  document.body.appendChild(link)
  link.click()
  document.body.removeChild(link)
}

const causeEnding = getCauseEnding()
const transactionType = [
  { name: t('detail.transactions.type.all'), value: null },
  { name: t('detail.transactions.type.payment'), value: [0.01, Infinity] },
  { name: t('detail.transactions.type.free'), value: [-Infinity, 0] }
]
const items = [
  {
    label: t('detail.transactions.actions.export_excel'),
    command: () => {
      exportToExcel()
    }
  },
  {
    label: t('detail.transactions.actions.export_csv'),
    command: () => {
      exportCSV()
    }
  }
]
const filters = ref({
  'station.location.organization.name': { value: [], matchMode: FilterMatchMode.IN },
  cost: { value: null, matchMode: FilterMatchMode.BETWEEN }
})

const getUniqueOrganizations = (transactions: Transaction[]) => {
  const orgs = new Set(
    transactions.map((t) =>
      JSON.stringify({
        name: t.station['location'].organization.name,
        id: t.station['location'].organization.id
      })
    )
  )
  return Array.from(orgs).map((org) => JSON.parse(org))
}
const getTransactions = async () => {
  try {
    let url = `${RouteNamespace.transactions}?limit=${rowsPerPage.value}&offset=${currentPage.value}`

    if (causeEndingFilter.value.length > 0) {
      const causeEndingParam = causeEndingFilter.value.join(',')
      url += `&causeEnding=${encodeURIComponent(causeEndingParam)}`
    }

    if (Array.isArray(transactionDates.value) && transactionDates.value.length === 2) {
      const transactionDatesFormatted = transactionDates.value.map((date) => formatDate(date))
      url += `&start=${transactionDatesFormatted[0]}&end=${transactionDatesFormatted[1]}`
    }

    const response = await ApiService.readAllEntities<TransactionResponse>(url)
    transactions.value = response['transactions']
    totalRecords.value = response['totalRecords']
    organizationFilter.value = getUniqueOrganizations(transactions.value)
  } catch (error) {
    console.error('Error retrieving transactions:', error)
  }
}

const organizationFilter = ref<string[]>([])
const causeEndingFilter = ref<string[]>([])

const formatDate = (date: Date): string => {
  const day = String(date.getDate()).padStart(2, '0')
  const month = String(date.getMonth() + 1).padStart(2, '0')
  const year = date.getFullYear()
  return `${year}-${month}-${day}`
}

const handleDatesSelected = (value: Date | Date[] | (Date | null)[] | null | undefined) => {
  if (
    Array.isArray(value) &&
    value.length === 2 &&
    value[0] instanceof Date &&
    value[1] instanceof Date
  ) {
    transactionDates.value = value
    getTransactions()
  }
}
const downloadReport = async (transaction: Transaction, type: TypeDocument) => {
  try {
    loading.value = true
    await ApiService.downloadEntity(`${RouteNamespace.files}/${type}/${transaction['id']}`)
  } catch (error) {
    console.error('Error downloading transaction report:', error)
    toast.add({
      group: 'error',
      severity: 'error',
      summary: t('detail.transactions.notifications.errorDownload'),
      life: 5000
    })
  } finally {
    loading.value = false
  }
}

const handleDuration = (milliseconds: number): string => {
  const totalSeconds = Math.floor(milliseconds / 1000)
  const hours = Math.floor(totalSeconds / 3600)
  const minutes = Math.floor((totalSeconds % 3600) / 60)
  const seconds = totalSeconds % 60

  return `${hours}h ${minutes}m ${seconds}s`
}

onMounted(async () => {
  loading.value = true
  await getTransactions()
  loading.value = false
})
</script>

<template>
  <AppTopbar>
    <template #header>
      <div class="flex flex-column h-4rem text-3xl">
        <div class="flex flex-row ml-2 justify-content-between align-items-center">
          <div class="flex">
            <svg-icon name="organization" size="24" color="#626868" />
            <span class="font-bold ml-2 text-2xl">{{ t('dashboard.transactions') }}</span>
          </div>
          <SplitButton
            icon="pi pi-download"
            :label="t('detail.transactions.actions.export')"
            :model="items"
            rounded
            :pt="{
              icon: {
                class: ['text-xl']
              }
            }"
          />
        </div>
      </div>
    </template>
    <template #body>
      <div class="flex">
        <div class="flex align-items-center w-full">
          <DatePicker
            class="date-picker mr-2 shadow-none"
            v-model="transactionDates"
            showIcon
            selectionMode="range"
            :manualInput="false"
            :dateFormat
            :placeholder="t('detail.transactions.header.rangeDates')"
            @update:model-value="handleDatesSelected"
            showButtonBar
            :maxDate="new Date()"
            @clear-click="getTransactions"
          />
          <MultiSelect
            v-if="role.name === Roles.admin"
            v-model="filters['station.location.organization.name'].value"
            display="chip"
            class="col-3 multi-select align-items-center mr-2"
            :maxSelectedLabels="6"
            :placeholder="t('detail.transactions.header.organization')"
            :options="organizationFilter"
            optionLabel="name"
            optionValue="name"
          >
            <template #option="slotProps">
              <div class="flex align-items-center">
                <div class="text-color">{{ capitalizeString(slotProps.option.name) }}</div>
              </div>
            </template>
            <template #chip="slotProps">
              <div class="organization-custom-chip">
                {{ capitalizeString(slotProps.value) }}
              </div>
            </template>
          </MultiSelect>
          <Select
            v-model="filters['cost'].value"
            class="h-3rem align-items-center"
            :options="transactionType"
            optionLabel="name"
            optionValue="value"
            :placeholder="t('detail.transactions.transactionType')"
            :pt="{
              item: ({ props, state, context }) => ({
                class: context.selected
                  ? 'bg-gray-300'
                  : context.focused
                    ? 'bg-gray-100'
                    : undefined
              })
            }"
          />
          <MultiSelect
            v-model="causeEndingFilter"
            display="chip"
            class="multi-select ml-2 align-items-center"
            :maxSelectedLabels="6"
            :placeholder="t('detail.transactions.header.cause_ending')"
            :options="causeEnding"
            optionLabel="name"
            optionValue="status"
            @change="getTransactions"
          >
            <template #option="slotProps">
              <div class="flex align-items-center">
                <div class="text-color">{{ slotProps.option.name }}</div>
              </div>
            </template>
          </MultiSelect>
        </div>
      </div>
    </template>
  </AppTopbar>
  <div class="card h-fit bg-white shadow-none">
    <DataTable
      v-model:expanded-rows="expandedRows"
      v-model:selection="selectedProduct"
      :globalFilterFields="['station.location.organization.name']"
      :filters="filters"
      :value="transactions"
      ref="csv"
      editMode="row"
      :rows="rowsPerPage"
      dataKey="id"
      :exportFilename="`transactions-${new Date().toLocaleDateString()}`"
      scrollable
    >
      <template #empty>
        <BaseLottieAnimation :icon="clock" :label="t('detail.transactions.notFound')" />
      </template>
      <Column expander class="table__expander" />
      <Column
        field="cost"
        :header="t('detail.transactions.header.cost')"
        header-class="font-bold"
        class="table__cost"
      >
        <template #body="slotProps">
          {{ `${slotProps['data']['cost'].toFixed(2)} ${slotProps['data']['currency']}` }}
        </template>
      </Column>
      <Column
        field="createdAt"
        :header="t('detail.transactions.header.start')"
        header-class="font-bold"
        class="table__start"
      >
        <template #body="slotProps">
          {{ new Date(slotProps['data']['createdAt']).toLocaleString() }}
        </template>
      </Column>
      <Column
        field="duration"
        :header="t('detail.transactions.header.duration')"
        header-class="font-bold"
        class="table__duration"
      >
        <template #body="slotProps">
          {{ handleDuration(slotProps['data']['duration']) }}
        </template>
      </Column>
      <Column
        field="energy"
        :header="t('detail.transactions.header.energySupplied')"
        header-class="font-bold"
        class="table__energy"
      >
        <template #body="slotProps">
          {{ `${slotProps['data']['energy']} ${UnitOfMeasure.KWH}` }}
        </template>
      </Column>
      <Column
        field="station.alias"
        :header="t('detail.transactions.header.chargePoint')"
        header-class="table__header font-bold"
        rowStyleClass="bg-gray-100"
        class="table__station"
      >
        <template #body="slotProps">
          <div class="flex flex-row justify-content-center">
            {{ slotProps['data']['station']['alias'] }}
          </div>
        </template>
      </Column>
      <Column
        field="connector.connection"
        :header="t('detail.transactions.header.connector')"
        header-class="table__header font-bold"
        rowStyleClass="bg-gray-100"
        class="table__connection"
      >
        <template #body="slotProps">
          <div class="flex flex-row justify-content-center">
            <Tag :value="slotProps['data'].connector?.connection" rounded />
          </div>
        </template>
      </Column>
      <Column
        field="causeEnding"
        :header="t('detail.transactions.header.cause_ending')"
        header-class="font-bold"
        class="table__cause"
      >
        <template #body="slotProps">
          <div class="flex align-items-center">
            <svg-icon
              class="mr-2"
              :name="getIconForCauseEnding(slotProps.data.causeEnding)"
              size="24"
              color="#9E9E9E"
            />
            {{ evaluateStatus(slotProps['data']['causeEnding']) }}
          </div>
        </template>
      </Column>
      <Column
        v-if="role.name === Roles.admin"
        :header="t('detail.transactions.header.ticket')"
        header-class="table__header font-bold"
        class="table__actions"
      >
        <template #body="slotProps">
          <div class="flex flex-row justify-content-center">
            <Button
              v-tooltip.top="t('detail.transactions.actions.download_ticket')"
              rounded
              class="button button-normal"
              :class="slotProps.data['ticket'].trim() === '' ? 'hidden' : ''"
              @click="downloadReport(slotProps.data, TypeDocument.TICKETS)"
            >
              <template #icon>
                <svg-icon name="invoice" size="16" color="#626868" />
              </template>
            </Button>
          </div>
        </template>
      </Column>
      <Column
        v-if="role.name === Roles.admin"
        :header="t('detail.transactions.header.invoice')"
        header-class="table__header font-bold"
        class="table__actions"
      >
        <template #body="slotProps">
          <div class="flex flex-row justify-content-center">
            <Button
              v-tooltip.top="t('detail.transactions.actions.download_invoice')"
              rounded
              class="button button-start"
              :class="slotProps.data['invoice'].trim() === '' ? 'hidden' : ''"
              @click="downloadReport(slotProps.data, TypeDocument.INVOICES)"
            >
              <template #icon>
                <svg-icon name="invoice" size="16" color="#ffffff" />
              </template>
            </Button>
          </div>
        </template>
      </Column>
      <template #footer>
        <BasePaginator
          v-model:current-page="currentPage"
          v-model:rows-per-page="rowsPerPage"
          :totalRecords
          @refresh-data="getTransactions"
        />
      </template>
      <template #expansion="slotProps">
        <div class="flex flex-row p-1 justify-content-around">
          <div class="flex flex-column h-2rem justify-content-between">
            <div class="flex">
              <svg-icon name="contract" size="18"></svg-icon>
              <span class="font-bold font-bold ml-2"
                >{{ t('detail.transactions.header.contract') }}:</span
              >
              <span class="ml-2">{{
                slotProps?.data?.station?.customer?.contracts?.[0]?.reference.toUpperCase() ?? 'N/A'
              }}</span>
            </div>
          </div>
          <div class="flex flex-column h-2rem justify-content-between">
            <div class="flex">
              <svg-icon name="reimbursable" color="#EA2839" size="18"></svg-icon>
              <span class="font-bold ml-2">{{ t('detail.transactions.header.refund') }}</span>
              <span class="ml-2">{{ `${slotProps['data']['reimbursable']} €` }}</span>
            </div>
          </div>
        </div>
      </template>
    </DataTable>
  </div>
  <BasePushNotification group="error" icon-name="restart" color="#EA2839" />
</template>
<style scoped lang="scss">
.checkbox-header {
  width: 1.5rem;
  height: 1.5rem;
}

input[type='checkbox']:active {
  border: 1px solid var(--lightGrayDetail) !important;
  background-color: var(--red) !important;
}

.checkbox-header:checked {
  border: 1px solid var(--lightGrayDetail) !important;
  background-color: var(--red) !important;
}

::v-deep(.p-inputtext) {
  border: 1px solid var(--lightGrayDetail) !important;
}

::v-deep(.p-inputtext) {
  border: 1px solid var(--lightGrayDetail) !important;
}

::v-deep(.table__header) > div > span {
  margin: 0 auto;
}

::v-deep(.table__expander) {
  width: 2%;
}

::v-deep(.table__cost) {
  width: 10%;
}

::v-deep(.table__start) {
  width: 15%;
}

::v-deep(.table__duration) {
  width: 12%;
}

::v-deep(.table__energy) {
  width: 15%;
}

::v-deep(.table__station) {
  width: 21%;
}

::v-deep(.table__connection) {
  width: 5%;
}

::v-deep(.table__cause) {
  width: 20%;
}

::v-deep(.table__actions) {
  width: 4%;
}

::v-deep(.date-picker) {
  min-width: 20rem;
}

::v-deep(.multi-select) {
  min-width: 11rem;
}

.organization-custom-chip {
  background-color: var(--chip);
  color: var(--darkGray);
  padding: 5px 10px;
  border-radius: 5px;
  font-weight: bold;
}
</style>
