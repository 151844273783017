<script setup lang="ts">
import { computed, onMounted, ref } from 'vue'
import { storeToRefs } from 'pinia'
import type { DataTableRowClickEvent } from 'primevue/datatable'
import { useToast } from 'primevue/usetoast'
import clock from '@/assets/lottie/clock.json'
import { t } from '@/common/i18n'
import SvgIcon from '@/common/icons/SvgIcon.vue'
import LocationCreationDialog from '@/components/locations/LocationCreationDialog.vue'
import LocationUploadDialog from '@/components/locations/LocationUploadDialog.vue'
import BaseConfirmDeletePopup from '@/components/ui/BaseConfirmDeletePopup.vue'
import BaseLottieAnimation from '@/components/ui/BaseLottieAnimation.vue'
import BaseMapContainerLocations from '@/components/ui/BaseMapContainerLocations.vue'
import BasePaginator from '@/components/ui/BasePaginator.vue'
import BasePushNotification from '@/components/ui/BasePushNotification.vue'
import AppTopbar from '@/layout/AppTopbar.vue'
import { type Location } from '@/models'
import { RouteNamespace } from '@/models/common/RouteNameSpace'
import type { LocationResponse } from '@/models/domain/location/api/Location'
import { ChargePointStatus, WebSocketStatus } from '@/models/ocpp/enums'
import router from '@/router'
import { ApiService } from '@/services/ApiService'
import { useApi } from '@/stores/api/api'
import { useChargePointStatusStore } from '@/stores/ocpp/chargePointStatus.ts'
import { useChargerConnectionStatusStore } from '@/stores/ocpp/chargerConnectionStatus.ts'
import { capitalizeString } from '@/utils/capitalize'
import { getClassForStatusStore } from '@/utils/status.ts'

const { loading } = storeToRefs(useApi())
const chargePointStatusStore = useChargePointStatusStore()
const chargerConnectionStatusStore = useChargerConnectionStatusStore()
const toast = useToast()

const activeCreationDialog = ref<boolean>(false)
const activeUpdateDialog = ref<boolean>(false)
const activeUploadDialog = ref<boolean>(false)
const hideMap = ref<boolean>(true)
const locations = ref<Location[]>([])
const popup = ref()
const totalRecords = ref<number>(0)
const updatedLocation = ref<Partial<Location>>()
const updatedLocationId = ref<string>('')

const currentPage = ref<number>(0)
const rowsPerPage = ref<number>(10)

const filters = ref<{ id: string[]; status: string[] }>({
  id: [],
  status: []
})
const statuses = ref([
  { name: t('status.available'), status: ChargePointStatus.AVAILABLE },
  { name: t('status.unavailable'), status: ChargePointStatus.UNAVAILABLE },
  { name: t('status.incidence'), status: ChargePointStatus.FAULTED },
  { name: t('status.disconnected'), status: WebSocketStatus.DISCONNECTED }
])

const getLocations = async () => {
  try {
    const response = await ApiService.readAllEntities<LocationResponse>(
      `${RouteNamespace.locations}?limit=${rowsPerPage.value}&offset=${currentPage.value}`
    )
    locations.value = response['locations']
    totalRecords.value = response['totalRecords']
  } catch (error) {
    console.error('Error occurred while fetching data:', error)
  }
}

const handleCreate = () => {
  activeCreationDialog.value = true
}

const handleDetail = (rowClickEvent: DataTableRowClickEvent) => {
  router.push({
    name: RouteNamespace.location,
    params: { id: rowClickEvent['data']['id'] }
  })
}

const actionsRemoveLocation = async (id: string) => {
  try {
    const payload = {
      isDeleted: true
    }
    await ApiService.updateEntity(RouteNamespace.locations, id, payload)
    toast.add({
      group: 'success',
      severity: 'success',
      summary: t('detail.station.notifications.deleteSuccess'),
      life: 3000
    })
  } catch (error) {
    console.error('Error deleting station:', error)
  } finally {
    await getLocations()
  }
}

const handleRemoveLocation = async (event: Event, id: string) => {
  popup.value.showConfirmPopup(
    event,
    async () => {
      loading.value = true
      await actionsRemoveLocation(id)
      loading.value = false
    },
    undefined
  )
}

const handleUploadDialog = () => {
  activeUploadDialog.value = true
}

const handleUpdate = (row: Location) => {
  updatedLocation.value = {
    address: row.address,
    city: row.city,
    country: row.country,
    description: row.description,
    energySources: row.energySources,
    name: row.name,
    postcode: row.postcode,
    province: row.province,
    organization: row.organization
  }
  updatedLocationId.value = row.id
  activeUpdateDialog.value = true
}

const filteredLocations = computed(() =>
  locations.value.filter((location) => {
    if (filters.value.id.length > 0 && !filters.value.id.includes(location.id)) return false
    if (!(filters.value.status.length > 0)) return true
    const filterSet = new Set(filters.value.status)
    for (const station of location.stations) {
      if (
        !chargerConnectionStatusStore.chargersConnectionStatus[station.cpId] ||
        chargerConnectionStatusStore.chargersConnectionStatus[station.cpId] ===
          WebSocketStatus.DISCONNECTED
      ) {
        if (filterSet.has(WebSocketStatus.DISCONNECTED)) return true
      } else if (
        filterSet.has(chargePointStatusStore.chargePointsStatuses[`${station.cpId}:0`]?.status)
      ) {
        return true
      }
    }
    return false
  })
)

onMounted(async () => {
  try {
    loading.value = true
    //open modal dialog from query param
    if (router.currentRoute.value.query.active) {
      activeCreationDialog.value = true
    }
    await getLocations()
  } catch (error) {
    console.error('Error occurred while fetching data:', error)
  } finally {
    loading.value = false
  }
})
</script>
<template>
  <AppTopbar>
    <template #header>
      <div class="flex flex-column h-4rem text-3xl">
        <div class="flex flex-row ml-2 justify-content-between align-items-center gap-4">
          <div class="flex">
            <svg-icon name="location" size="24" color="#626868" />
            <span class="font-bold ml-2 text-2xl">{{ t('dashboard.locations') }}</span>
          </div>
          <div class="flex justify-content-center gap-3">
            <Button
              v-tooltip.top="t('detail.location.actions.createBulk')"
              rounded
              class="button button-save shadow-1"
              @click="handleUploadDialog"
            >
              <template #icon>
                <i class="font-bold pi pi-upload text-lg text-white" />
              </template>
            </Button>
            <Button
              v-tooltip.top="t('detail.location.actions.create')"
              rounded
              class="button button-normal shadow-1"
              @click="handleCreate"
            >
              <template #icon>
                <svg-icon name="add" size="20" color="white" />
              </template>
            </Button>
          </div>
        </div>
      </div>
    </template>
    <template #body>
      <div class="flex flex-row gap-4 justify-content-between">
        <div class="flex flex-row gap-2 overflow-hidden">
          <MultiSelect
            v-model="filters['id']"
            class="multi-select align-items-center"
            :maxSelectedLabels="3"
            display="chip"
            :placeholder="t('detail.location.header.placeholder.location')"
            :options="locations"
            optionLabel="name"
            optionValue="id"
            :loading
          >
            <template #dropdownicon>
              <svg-icon name="location" size="18" color="#9E9E9E" />
            </template>
            <template #option="slotProps">
              <div class="flex align-items-center">
                <div class="text-color">{{ capitalizeString(slotProps.option.name) }}</div>
              </div>
            </template>
          </MultiSelect>
          <MultiSelect
            v-model="filters['status']"
            class="multi-select align-items-center"
            :maxSelectedLabels="4"
            display="chip"
            :placeholder="t('detail.location.header.placeholder.status')"
            :options="statuses"
            optionLabel="name"
            optionValue="status"
          >
            <template #option="slotProps">
              <div class="flex align-items-center">
                <div
                  :class="`status status__${getClassForStatusStore(slotProps.option.status)} mr-1`"
                />
                <small
                  class="font-bold"
                  :class="`badgeStatus badgeStatus__${getClassForStatusStore(slotProps.option.status)}`"
                  >{{ capitalizeString(slotProps.option.name) }}</small
                >
              </div>
            </template>
          </MultiSelect>
        </div>
        <ToggleButton
          v-model="hideMap"
          class="toggle-button mr-2"
          :onLabel="t('topbar.showMap')"
          :offLabel="t('topbar.hideMap')"
          icon-pos="left"
          onIcon="pi pi-eye"
          offIcon="pi pi-eye-slash"
        />
      </div>
    </template>
  </AppTopbar>
  <section>
    <div class="card h-fit bg-white shadow-none">
      <BaseMapContainerLocations
        v-if="!hideMap"
        incidence="3"
        :isDraggable="false"
        :locations="filteredLocations"
        :zoom="5"
      />
      <DataTable
        :value="filteredLocations"
        size="large"
        :rows="rowsPerPage"
        :rowHover="true"
        dataKey="id"
        @rowClick="handleDetail($event)"
        scrollable
      >
        <template #empty>
          <BaseLottieAnimation :icon="clock" :label="t('detail.location.notFound')" />
        </template>
        <Column
          field="name"
          :header="t('detail.location.header.name')"
          header-class="font-bold"
          :showClearButton="true"
          :showFilterMenu="false"
          class="table__name"
        >
          <template #body="{ data }">
            <div class="flex align-items-center gap-2">
              <span>{{ capitalizeString(data.name) }}</span>
            </div>
          </template>
        </Column>
        <Column
          field="address"
          :header="t('detail.location.header.address')"
          header-class="font-bold"
          class="table__address"
        >
          <template #body="slotProps">
            {{ capitalizeString(slotProps.data['address']) }}
          </template>
        </Column>
        <Column
          field="charger"
          :header="t('detail.location.header.chargePoints')"
          header-class="table__header font-bold"
          rowStyleClass="bg-gray-100"
          class="table__chargers"
        >
          <template #body="slotProps">
            <div class="flex flex-row justify-content-center">
              <Tag :value="slotProps.data['stations'].length" rounded />
            </div>
          </template>
        </Column>
        <Column :header="t('detail.header.actions')" header-class="table__header font-bold">
          <template #body="slotProps">
            <div class="flex flex-row justify-content-center">
              <Button
                class="button button-normal mr-2"
                v-tooltip.top="t('detail.location.actions.update')"
                rounded
                @click="handleUpdate(slotProps.data)"
              >
                <template #icon>
                  <svg-icon name="edit" size="18" color="#626868" />
                </template>
              </Button>
              <BaseConfirmDeletePopup ref="popup" />
              <Button
                class="button button-remove"
                v-tooltip.top="t('detail.location.actions.delete')"
                rounded
                @click="handleRemoveLocation($event, slotProps.data.id)"
              >
                <template #icon>
                  <svg-icon name="trash" size="16" />
                </template>
              </Button>
            </div>
          </template>
        </Column>
        <template #footer>
          <div class="flex flex-row justify-content-center align-items-center">
            <BasePaginator
              v-model:current-page="currentPage"
              v-model:rows-per-page="rowsPerPage"
              :totalRecords
              @refresh-data="getLocations"
            />
          </div>
        </template>
      </DataTable>
    </div>
    <LocationCreationDialog
      v-model:visible="activeCreationDialog"
      :toasting="toast"
      @refresh-locations="getLocations()"
    />
    <LocationCreationDialog
      v-model:visible="activeUpdateDialog"
      updating
      :updated-location="updatedLocation"
      :updated-location-id="updatedLocationId"
      :toasting="toast"
      @refresh-locations="getLocations()"
    />
    <LocationUploadDialog
      v-model:visible="activeUploadDialog"
      :toasting="toast"
      @refresh-locations="getLocations()"
    />
    <BasePushNotification group="success" icon-name="success" color="#00DB7F" />
    <BasePushNotification group="error" icon-name="error" color="#EA2839" />
  </section>
</template>

<style scoped>
::v-deep(.table__header) > div > span {
  margin: 0 auto;
}

::v-deep(.table__name) {
  width: 27%;
}

::v-deep(.table__address) {
  width: 42%;
}

::v-deep(.table__chargers) {
  width: 15%;
}

::v-deep(.table__actions) {
  width: 16%;
}

::v-deep(.multi-select) {
  min-width: 11rem;
}

::v-deep(.toggle-button) {
  min-width: 11rem;
  min-height: 40px;
}
</style>
