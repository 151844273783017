const startDate1 = new Date()
startDate1.setHours(9)
startDate1.setMinutes(0)
const endDate1 = new Date()
endDate1.setHours(14)
endDate1.setMinutes(0)
const startDate2 = new Date()
startDate2.setHours(15)
startDate2.setMinutes(0)
const endDate2 = new Date()
endDate2.setHours(20)
endDate2.setMinutes(0)
const startDate3 = new Date()
startDate3.setHours(14)
startDate3.setMinutes(0)
const endDate3 = new Date()
endDate3.setHours(20)
endDate3.setMinutes(0)

export const dummySchedules = [
  {
    cost: 1,
    days: [1, 2, 3, 4, 5],
    timeRange: {
      start: startDate1,
      end: endDate1
    }
  },
  {
    cost: 2,
    days: [6, 0],
    timeRange: {
      start: startDate2,
      end: endDate2
    }
  },
  {
    cost: 5,
    days: [5],
    timeRange: {
      start: startDate3,
      end: endDate2
    }
  }
]
